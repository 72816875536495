//import auth from "./firebaseApp";
import { 
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  getAuth,
} from 'firebase/auth';

//import { firestoreMethods } from './firestoreMethods';
//import { utilMethods } from '../util/utilMethods';


// firebase Auth helper methods go here... 
export const authMethods = {

  isAuthUser: (userEmail) => {
    const authUsers = [
      'ilya.mezheritsky@gmail.com', 
      'ilya@auditcake.com',
      'dbratshpis@gmail.com',
      'dan@auditcake.com',
    ];

    return authUsers.includes(userEmail);
  },

  signInWithGoogle: async (setErrors) => {
    try {
      const auth = getAuth();
      const googleProvider = new GoogleAuthProvider();
      const res = await signInWithPopup(auth, googleProvider);
      const user = res.user;
      const isAuthorized = authMethods.isAuthUser(user.email);
      console.log('isAuthorized', isAuthorized)
      if (isAuthorized) {
        // Handle authorized user
        console.log('Welcome, ' + user.displayName);
        // ... integrate user with your application
      } else {
        await signOut(auth);

        console.log('Signin failed: Your domain is not authorized.');
        // Display an error message to the user
      }
      setErrors(null);
    } catch (err) {
      console.log('sign-in error', err)
      setErrors(["Sign-in with google failed."]);
    }   
  },

  signOut: async () => {
    const auth = getAuth();
    signOut(auth);
  }, 
}
