import db from "./firebaseApp";
import { 
  doc,
  collection,
  setDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  getDoc,
  getDocs,
  writeBatch,
  arrayUnion, 
  arrayRemove,
  query,
  where,
  //onSnapshot,
} from 'firebase/firestore'

import { utilMethods } from '../util/utilMethods';
import { stepMethods } from '../util/stepMethods';



// firebase Firestore helper methods go here... 

export const firestoreMethods = {
  
  getD: async (col, id) => {
    try {
      console.log("FIRESTORE GETD (col, id): ", col, id);

      const docRef = doc(db, col, id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        //console.log("Document data:", docSnap.data());
        return {id: docSnap.id, ...docSnap.data()}
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document: ", id);
      }
    } catch (err) {
        console.log("GetD Failed: ", err, col);
        utilMethods.customLog(`GetD Failed: ${err}, ${col}`);
        return {}

    }
  },

  get: async (col, q, q2) => {
    try {
      console.log("FIRESTORE GET (col, q): ", col, q, q2);

      // TODO, eventually will need pagination, or optimization to not get everything
      /*const querySnapshot = await getDocs((q) ? 
        query(collection(db, col), where(q.name, q.op, q.value)) :
        collection(db, col)
      );*/

      const baseQuery = (q) ? 
        query(collection(db, col), where(q.name, q.op, q.value)) :
        collection(db, col);

      const finalQuery = (!q2) ? 
        baseQuery : 
        query(
          baseQuery, 
          where(q2.name, q2.op, q2.value)
      );

      const querySnapshot = await getDocs(finalQuery);

      //console.log(col, q, querySnapshot)

      const items = []
      querySnapshot.forEach((doc) => {
        //console.log('doc', doc)
        items.push({id: doc.id, ...doc.data()})
      });

      return utilMethods.sortByDate(items, true);

    } catch (err) {
        console.log("Get Failed: ", err, col);
        utilMethods.customLog(`Get Failed: ${err}, ${col}`);
    }
  },

/*
  onSnapshot: (col, setCallStatus) => {
    //callUpdates
    
    console.log("FIRESTORE SNAPSHOT: ", col);

    //const newRef = await addDoc(collection(db, col), obj);
    const unsubscribe = onSnapshot(collection(col), // Reference Pub/Sub messages collection
      (docSnapshot) => {
        const data = docSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        // Access the latest update (assuming documents are ordered by timestamp)
        setCallStatus(data[data.length - 1]);
      },
      (error) => {
        console.error('Error fetching call updates:', error);
      }
    );
    console.log("unsubscribe", unsubscribe)
    return unsubscribe;
  },
*/

  add: async (col, obj, refresh) => {
    try {
      console.log("FIRESTORE ADD: ", obj);

      const newRef = await addDoc(collection(db, col), obj);
      if (refresh) {
        refresh(true);
      }
      return newRef;
    } catch (err) {
      utilMethods.customLog(`Add Failed: ${err}, ${col}, ${obj}`);
      console.log("Add Failed: ", err);
    }
  },

  set: async (col, obj, id) => {
    try {
      console.log("FIRESTORE SET: ", obj, id);

      const newRef = await setDoc(doc(db, col, id), obj);
      return newRef;
    } catch (err) {
      utilMethods.customLog(`SET Failed: ${err}, ${col}, ${obj}`);
      console.log("Set Failed: ", err);
    }
  },

  remove: async (col, id, refresh) => {
    try {
      const docRef = doc(db, col, id);
      await deleteDoc(docRef);
      if (refresh) {
        refresh(true);
      }
    } catch (err) {
      utilMethods.customLog(`Remove Failed: ${err}, ${col}, ${id}`);
      console.log("Remove Failed: ", err);
    }
  },

  update: async (col, id, updates, refresh) => {
    console.log("FIRESTORE UPDATE: ", col, id, updates);

    try {
      let updateData = {};
      for (const u of updates) {
        switch (u.op) {
          case "union":
            //console.log('HERE', u.name, "value", u.value);
            updateData[u.name]=arrayUnion(u.value)
            break;
          case "remove":
            updateData[u.name]=arrayRemove(u.value)
            break;
          default: 
            updateData[u.name]=u.value
            break;
        }
      }
      const docRef = doc(db, col, id);
      await updateDoc(docRef, updateData);
      if (refresh) {
        refresh(true);
      }
    } catch (err) {
      utilMethods.customLog(`Update Failed: ${err}, ${col}, ${id}, ${updates}`);
      console.log("Update Failed: ", err, col, id, updates);
    }
  },

  addAttachments: async (atts) => {
    try {
      // Get a new write batch
      const batch = writeBatch(db);

      for (const att of atts) {
        const attRef = doc(collection(db, "attachments"));
        batch.set(attRef, {
          attId: att.attId,
          url: att.url,
          audit: att.audit,
          dateAdded: att.dateAdded,
        });
      }
      // Commit the batch
      await batch.commit();

    } catch (err) {
        console.log("Add Attachments Failed: ", err);
        utilMethods.customLog(`Add Attachments Failed: ${err}`);
    }
  },

  batchAddUploads: async(batchDoc, original, consolidated, transformed) => {
    console.log("in batchAddUploads", batchDoc);
    const uploadsRef = await firestoreMethods.add("uploads", batchDoc);
    //const auditsRef = await db.collection('uploads').add({});
    console.log(original, consolidated, transformed)

    try {
      const batch1 = writeBatch(db);

      // Add each audit object as a nested document with a unique ID
      for (const o of original.slice(50,134)) {
        const newRef = doc(collection(uploadsRef, 'original'));
        batch1.set(newRef, o);
      }
      await batch1.commit();

      const batch2 = writeBatch(db);

      for (const c of consolidated.slice(50, 85)) {
        const newRef = doc(collection(uploadsRef, 'consolidated'));
        batch2.set(newRef, c);
      }
      await batch2.commit();

      const batch3 = writeBatch(db);

      for (const t of transformed.slice(50, 85)) {
        const newRef = doc(collection(uploadsRef, 'transformed'));
        batch3.set(newRef, t);
      }
      await batch3.commit();
      console.log('Audits and audit details added to uploads successfully!');
    } catch (error) {
      console.error('Error processing audits:', error);
    }
  },

  batchAddAudits: async(audits, uploadId, debug, userEmail, userPhone, sliceID='99') => {
    console.log("in batchAddAudits", audits, uploadId, debug, userEmail, userPhone);
    //const uploadsRef = await firestoreMethods.add("uploads", batchDoc);
    //const auditsRef = await db.collection('uploads').add({});
    //console.log(original, consolidated, transformed)

    try {
      const batch = writeBatch(db);
      const createdAudits = {};

      // Add each audit object as a nested document with a unique ID
      for (const a of audits.slice(0, 40)) {
        //add audit

        // remove all white spaces
        for (const key in a) {
          //console.log(key);
          if (typeof a[key] === 'string') {
            a[key] = a[key].trim();
          }
        }

        const auditsRef = doc(collection(db, "audits"));
        //console.log("newRef", newRef, newRef.id);
        a["auditCode"]= auditsRef.id.slice(0,6).toUpperCase(); //assign a refId to each audit
        // TODO: could move this setComplete in the future
        const firstHistory = {
          op: "Created",
          date: new Date(),
          userId: "AuditCake", 
          batchId: uploadId,
        }
        if (a.id) firstHistory['uploadId'] = a.id;
        if (a.xmlUrl) firstHistory['xml'] = true;
        a["history"] = [firstHistory];

        // don't remember why dateAdded is getting set to something else, so overwrite here
        a["dateAdded"] = new Date();
        // if in debug, substitute with userEmail and userPhone
        if (debug) {
          a["insuredEmail"] = userEmail
          a["insuredPhone"] = userPhone
          a["agentEmail"] = userEmail
          a["agentPhone"] = userPhone
        } else {
          a["env"] = 'prod'
        }

        a["sliceID"] = sliceID;

        // add initial state to an audit
        a["bakeState"] = "READY";

        console.log("A", a);
        delete a.id; //removce id of the upload

        batch.set(auditsRef, a);
        createdAudits[auditsRef.id] = a;
      }
      await batch.commit();

      // create step
      for (const [auditFSID, createdAudit] of Object.entries(createdAudits)) {
        console.log("createdAudit", auditFSID, createdAudit);
        const step = {
          auditFSID: auditFSID, 
          auditCode: createdAudit.auditCode,
          type: 'NEW',
          audit: createdAudit};
        if (!createdAudit.xmlUrl) {
        //  await stepMethods.createStep({...createdAudit, id: auditFSID}, 'READY', {waitInDays: 0});  
        //} else {
          await stepMethods.setComplete({step});  
        }
      }

      console.log('Audits added successfully!');
    } catch (error) {
      console.error('Error processing audits:', error);
    }
  },
  


}