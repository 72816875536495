export const markUnproductive = (variables) => `We are closing this assignment as non-productive at this time based on our attempts to secure a completed audit outlined above.
If you desire additional handling of this assignment, please reorder thru your normal channels and we will reopen the
assignment.`;

export const finalSummary = (variables) => `${variables.insuredDbaName || variables.insuredCompany} is a ${variables.insuredDescriptionOfOps} in ${variables.insuredLocationState}. 
The insured provided a sales worksheet and sales tax returns for the audit period. 
${finalString(variables.categories)}`;

const finalString = (cats) => {
  //let t = 'Final audited exposures were ';
  // Step 1: Group data by Policy Type and calculate percentages
  const groupedData = {};
  cats.forEach(row => {
    const percentage = parseFloat(row.overwriteDiff ? row.overwriteDiff : row.diff);

    if (percentage !== null) {
      if (!groupedData[row['policyType']]) {
        groupedData[row['policyType']] = [];
      }
      groupedData[row['policyType']].push({
        classCode: row['classCode'],
        percentage,
      });
    }
  });

  const summaryParts = [];

  // Step 2: Check if percentages are the same within each Policy Type
  for (const [policyType, entries] of Object.entries(groupedData)) {
    const firstPercentage = entries[0].percentage;
    const allSamePercentage = entries.every(entry => entry.percentage === firstPercentage);

    if (allSamePercentage) {
      // Use Policy Type if percentages are the same
      const direction = firstPercentage > 0 ? 'higher' : 'lower';
      summaryParts.push(
        `${Math.abs(firstPercentage)}% ${direction} for ${policyType}`
      );
    } else {
      // Use Class Code if percentages are different
      entries.forEach(entry => {
        const direction = entry.percentage > 0 ? 'higher' : 'lower';
        summaryParts.push(
          `${Math.abs(entry.percentage)}% ${direction} for Class Code ${entry.classCode}`
        );
      });
    }
  }

  // Step 3: Format the summary based on the number of parts
  if (summaryParts.length === 1) {
    return `Final audited exposures were ${summaryParts[0]}.`;
  } else if (summaryParts.length === 2) {
    return `Final audited exposures were ${summaryParts.join(' and ')}.`;
  } else if (summaryParts.length > 2) {
    const lastPart = summaryParts.pop();
    return `Final audited exposures were ${summaryParts.join(', ')}, and ${lastPart}.`;
  }

  return ''; // Fallback in case there are no summary parts
  //return summaryParts.join(' ');

}

//Final audited exposures were 2.1% lower for GL and 0.9% lower for liquor liability.