// util helper methods go here... 
import { callTemplate } from './commsTemplates/CallTemplate'; 
import { callAgentTemplate } from './commsTemplates/CallAgentTemplate'; 
import { letterInsuredTemplate } from './commsTemplates/LOBTemplate'
import { txtTemplate } from './commsTemplates/TxtTemplate'; 
import { markUnproductive, finalSummary } from './commsTemplates/NotesTemplate'; 

import { 
  emailTemplateSubject,
  emailTemplate,
  //emailInsuredNewContactSubject, 
  //emailInsuredNewContactBody,
  //emailTemplate2X,
  //emailTemplateSubject2X,
  //emailTemplate3X,
  //emailTemplateSubject3X,
  emailInsuredSubmitDocsSubject, 
  emailInsuredSubmitDocsBody,
  emailInsuredWrongDocSubject, 
  emailInsuredWrongDocBody,
  
  emailConfDone, 
  emailConfDoneSubject,

  emailAgentKickOffSubject,
  emailAgentKickOffBody,
  emailAgentProvidedSubject,
  emailAgentProvidedBody,
  emailAgentForContactSubject,
  emailAgentForContactBody,
} from './commsTemplates/Emails'; 


export const commsMethods = {

  "callTemplate": (inputs) => {
    return callTemplate(inputs); 
  },
  "callAgentTemplate": (inputs) => {
    return callAgentTemplate(inputs);
  },
  "txtTemplate": (inputs) => {
    return txtTemplate(inputs); 
  },
  
  "letterInsuredTemplate": (inputs) => {
    return letterInsuredTemplate(inputs); 
  },

  "markUnproductive": (inputs) => {
    return markUnproductive(inputs); 
  },

  "finalSummary": (inputs) => {
    return finalSummary(inputs); 
  },

  "emailAgentKickOffSubject": (inputs) => {
    return emailAgentKickOffSubject(inputs); 
  },
  "emailAgentKickOffBody": (inputs) => {
    return emailAgentKickOffBody(inputs); 
  },
  "emailAgentProvidedSubject": (inputs) => {
    return emailAgentProvidedSubject(inputs); 
  },
  "emailAgentProvidedBody": (inputs) => {
    return emailAgentProvidedBody(inputs); 
  },
  "emailAgentForContactSubject": (inputs) => {
    return emailAgentForContactSubject(inputs); 
  },
  "emailAgentForContactBody": (inputs) => {
    return emailAgentForContactBody(inputs); 
  },
 
  "emailTemplateSubject": (inputs) => {
    return emailTemplateSubject(inputs); 
  },
  "emailTemplate": (inputs) => {
    return emailTemplate(inputs); 
  },
  /*
  "txtTemplate2": (inputs) => {
    return txtTemplate2(inputs); 
  },
  "emailInsuredNewContactSubject": (inputs) => {
    return emailInsuredNewContactSubject(inputs); 
  },

  "emailInsuredNewContactBody": (inputs) => {
    return emailInsuredNewContactBody(inputs); 
  },
  "emailTemplate2X": (inputs) => {
    return emailTemplate2X(inputs); 
  },
  "emailTemplateSubject2X": (inputs) => {
    return emailTemplateSubject2X(inputs); 
  },
  "emailTemplate3X": (inputs) => {
    return emailTemplate3X(inputs); 
  },
  "emailTemplateSubject3X": (inputs) => {
    return emailTemplateSubject3X(inputs); 
  },
  */
  
  "emailInsuredSubmitDocsSubject": (inputs) => {
    return emailInsuredSubmitDocsSubject(inputs); 
  },
  "emailInsuredSubmitDocsBody": (inputs) => {
    return emailInsuredSubmitDocsBody(inputs); 
  },
  "emailInsuredWrongDocSubject": (inputs) => {
    return emailInsuredWrongDocSubject(inputs); 
  },
  "emailInsuredWrongDocBody": (inputs) => {
    return emailInsuredWrongDocBody(inputs); 
  },
  

  
  "emailConfDone": (inputs) => {
    return emailConfDone(inputs); 
  },

  "emailConfDoneSubject": (inputs) => {
    return emailConfDoneSubject(inputs); 
  },

}