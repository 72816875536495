// util helper methods go here... 

//import { firestoreMethods } from '../firebase/firestoreMethods';
//import { functionsMethods } from '../firebase/functionsMethods';
import { utilMethods } from '../util/utilMethods';

export const stepAutoMethods = {
  
  "NEW": async (step) => {
    const pushSteps =[];
    console.log("determine first steps:", step);
    const insuredEmail = utilMethods.validateEmail(step.audit.insuredEmail);
    const agentEmail = utilMethods.validateEmail(step.audit.agentEmail);
    //const insuredPhone = true;//utilMethods.validateEmail(step.audit.insuredEmail);

    pushSteps.push("MAILING_INSURED");
    if (insuredEmail) pushSteps.push("EMAILING_INSURED");
    if (agentEmail) pushSteps.push("AGENT_EMAIL_KICKOFF");
    
    return pushSteps;
  },

  "AGENT_EMAIL_KICKOFF": async (step, allSteps, audit) => {
    console.log("AGENT_EMAIL_KICKOFF auto:", step);
    const pushSteps =[];
    pushSteps.push("WAITING_AGENT");
    return pushSteps;
  },
  "AGENT_EMAIL_PROVIDED_AGENT": async (step, allSteps) => {
    const pushSteps =[];
    console.log("AGENT_EMAIL_PROVIDED_AGENT auto:", step);
    pushSteps.push("WAITING_PROVIDED_AGENT");
    return pushSteps;
  },

  "AGENT_EMAIL_INSURED_BOUNCED": async (step, allSteps) => {
    const pushSteps =[];
    console.log("AGENT_EMAIL_INSURED_BOUNCED auto:", step);
    const waiting = allSteps.some(s => 
      (s.type === 'WAITING_AGENT' && !s.hasOwnProperty("dateCompleted")));
    if (!waiting) pushSteps.push("WAITING_AGENT"); // DON'T WAIT IF WE ARE ALREADY WAITING
    return pushSteps;
  },

  "WAITING_AGENT": async (step, allSteps, audit) => {
    const pushSteps =[];
    console.log("WAITING_AGENT auto:", step);
    // TODO: escalate logic FOR WHICH CALLING TO PUSH!

    const agentEntered = audit.history.some(a => a.op === 'Agent Opened Audit');
    pushSteps.push(agentEntered? 
      "RECEIVING_AGENT_INFO" :
      "WAITING_AGENT"
    );
    
    return pushSteps;
  },
  "WAITING_PROVIDED_AGENT": async (step, allSteps, audit) => {
    const pushSteps =[];
    console.log("WAITING_PROVIDED_AGENT auto:", step);
    const invitedAgentEntered = audit.history.some(a => a.op === 'Provided Agent Opened Audit');
    pushSteps.push(invitedAgentEntered? 
      "RECEIVING_PROVIDED_AGENT_INFO" :
      "WAITING_PROVIDED_AGENT"
    );
    return pushSteps;
  },

  "RECEIVING_AGENT_INFO": async (step, allSteps, audit) => {
    const pushSteps =[];
    console.log("RECEIVING_AGENT_INFO auto (step, allsteps, audit):", step, allSteps, audit);
    
    const agentProvidedEmail = utilMethods.validateEmail(audit.agentProvidedEmail);
    const agentInsuredEmail = utilMethods.validateEmail(audit.agentInsuredEmail);

    if (agentInsuredEmail) {
      //console.log('are they equal', agentInsuredEmail, audit.insuredEmail)

      /// TODO: may need some fuzzy matching here?
      if (audit.agentInsuredEmail !== audit.insuredEmail) {
        pushSteps.push("INSURED_EMAIL_NEW_CONTACT")
      }
    } else if (agentProvidedEmail) {
      pushSteps.push("AGENT_EMAIL_PROVIDED_AGENT")
    } 
    return pushSteps;
  },

  "RECEIVING_PROVIDED_AGENT_INFO": async (step, allSteps, audit) => {
    const pushSteps =[];
    console.log("RECEIVING_PROVIDED_AGENT_INFO auto:", step);
    //const agentProvidedEmail = utilMethods.validateEmail(audit.agentProvidedEmail);
    const agentInsuredEmail = utilMethods.validateEmail(audit.agentInsuredEmail);

    if (agentInsuredEmail) {
      /// TODO: may need some fuzzy matching here?
      console.log('are they equal', agentInsuredEmail, audit.insuredEmail)
      if (audit.agentInsuredEmail !== audit.insuredEmail) {
        pushSteps.push("INSURED_EMAIL_NEW_CONTACT")
      }

    } 

    //TODO: don't know how to do this yet, without creating providedagent2
    //else if (agentProvidedEmail) {
    //  pushSteps.push("AGENT_EMAIL_PROVIDED_AGENT")
    //} 
    return pushSteps;
  },

  "MAILING_INSURED": async (step, allSteps) => {
    const pushSteps =[];
    console.log("MAILING_INSURED auto:", step);
    // Checks whether we are already waiting
    const waiting = allSteps.some(s => 
      (s.type === 'WAITING_INSURED' && !s.hasOwnProperty("dateCompleted")));
    if (!waiting) pushSteps.push("WAITING_INSURED");
    return pushSteps;
  },

  "MAILING_INSURED_LOCATION": async (step, allSteps) => {
    const pushSteps =[];
    console.log("MAILING_INSURED_LOCATION auto:", step);
    // Checks whether we are already waiting
    const waiting = allSteps.some(s => 
      (s.type === 'WAITING_INSURED' && !s.hasOwnProperty("dateCompleted")));
    if (!waiting) pushSteps.push("WAITING_INSURED");
    return pushSteps;
  },

  "CALLING_INSURED": async (step, allSteps) => {
    const pushSteps =[];
    console.log("CALLING_INSURED auto:", step);
    // Checks whether we are already waiting
    const waiting = allSteps.some(s => 
      (s.type === 'WAITING_INSURED' && !s.hasOwnProperty("dateCompleted")));
    if (!waiting) pushSteps.push("WAITING_INSURED");
    return pushSteps;
  },

  "CALLING_AGENT": async (step, allSteps) => {
    const pushSteps =[];
    console.log("CALLING_AGENT auto:", step);
    // Checks whether we are already waiting
    const waiting = allSteps.some(s => 
      (s.type === 'WAITING_AGENT' && !s.hasOwnProperty("dateCompleted")));
    if (!waiting) pushSteps.push("WAITING_AGENT");
    return pushSteps;
  },

  "TEXTING_INSURED": async (step, allSteps) => {
    const pushSteps =[];
    console.log("TEXTING_INSURED auto:", step);
    // Checks whether we are already waiting
    const waiting = allSteps.some(s => 
      (s.type === 'WAITING_INSURED' && !s.hasOwnProperty("dateCompleted")));
    if (!waiting) pushSteps.push("WAITING_INSURED");
    return pushSteps;
  },

  "EMAILING_INSURED": async (step, allSteps) => {
    const pushSteps =[];
    console.log("EMAILING_INSURED auto:", step);
    const waiting = allSteps.some(s => 
      (s.type === 'WAITING_INSURED' && !s.hasOwnProperty("dateCompleted")));
    if (!waiting) pushSteps.push("WAITING_INSURED");
    return pushSteps;
  },

  "EMAILING_INSURED_2X": async (step, allSteps) => {
    const pushSteps =[];
    console.log("EMAILING_INSURED_2X auto:", step);
    const waiting = allSteps.some(s => 
      (s.type === 'WAITING_INSURED' && !s.hasOwnProperty("dateCompleted")));
    if (!waiting) pushSteps.push("WAITING_INSURED");
    return pushSteps;
  },

  "INSURED_EMAIL_3X": async (step, allSteps) => {
    const pushSteps =[];
    console.log("INSURED_EMAIL_3X auto:", step);
    const waiting = allSteps.some(s => 
      (s.type === 'WAITING_INSURED' && !s.hasOwnProperty("dateCompleted")));
    if (!waiting) pushSteps.push("WAITING_INSURED");
    return pushSteps;
  },
  "INSURED_EMAIL_NEW_CONTACT": async (step, allSteps) => {
    const pushSteps =[];
    console.log("INSURED_EMAIL_NEW_CONTACT auto:", step);
    const waiting = allSteps.some(s => 
      (s.type === 'WAITING_INSURED' && !s.hasOwnProperty("dateCompleted")));
    if (!waiting) pushSteps.push("WAITING_INSURED");
    return pushSteps;
  },
  "INSURED_EMAIL_WRONG_DOC": async (step, allSteps) => {
    const pushSteps =[];
    console.log("INSURED_EMAIL_WRONG_DOC auto:", step);
    const waiting = allSteps.some(s => 
      (s.type === 'WAITING_INSURED' && !s.hasOwnProperty("dateCompleted")));
    if (!waiting) pushSteps.push("WAITING_INSURED");
    return pushSteps;
  },
  "INSURED_EMAIL_SUBMIT_DOCS": async (step, allSteps) => {
    const pushSteps =[];
    console.log("INSURED_EMAIL_SUBMIT_DOCS auto:", step);
    const waiting = allSteps.some(s => 
      (s.type === 'WAITING_INSURED' && !s.hasOwnProperty("dateCompleted")));
    if (!waiting) pushSteps.push("WAITING_INSURED");
    return pushSteps;
  },
  


  "WAITING_INSURED": async (step, allSteps, audit) => {
    const pushSteps =[];
    console.log("WAITING_INSURED auto:", step);
    const insuredEntered = audit.history.some(a => a.op === 'Opened Audit');
    // TODO: LOGIC FOR WHICH CALLING TO PUSH!
    if (insuredEntered) {
      pushSteps.push("RECEIVING_INFO")
    } else {
      pushSteps.push("EMAILING_INSURED_2X")
      //pushSteps.push("CALLING_INSURED_2X")
      //pushSteps.push("CALLING_AGENT") // also escalate to agent
    }
    return pushSteps;
  },


  "RECEIVING_INFO": async (step, allSteps, audit) => {
    const pushSteps =[];
    console.log("RECEIVING_INFO auto:", step);
    if (audit.submitted) {
      pushSteps.push("EMAILING_INSURED_DONE")
    } 
    return pushSteps;
  },

  "EMAILING_INSURED_DONE": async (step, allSteps, audit) => {
    const pushSteps =[];
    console.log("EMAILING_INSURED_DONE auto:", step);
    return pushSteps;
  },
  "SENT_TO_USLI_API": async (step, allSteps, audit) => {
    const pushSteps =[];
    console.log("SENT_TO_USLI_API auto:", step);
    return pushSteps;
  },
  "SENT_TO_CARRIER": async (step, allSteps, audit) => {
    const pushSteps =[];
    console.log("SENT_TO_CARRIER auto:", step);
    return pushSteps;
  },
  "MARK_UNPRODUCTIVE": async (step, allSteps, audit) => {
    const pushSteps =[];
    console.log("MARK_UNPRODUCTIVE auto:", step);
    return pushSteps;
  },
  "FINAL_SUMMARY": async (step, allSteps, audit) => {
    const pushSteps =[];
    console.log("FINAL_SUMMARY auto:", step);
    return pushSteps;
  },
  "MANUAL": async (step, allSteps, audit) => {
    const pushSteps =[];
    console.log("MANUAL auto:", step);
    return pushSteps;
  },
}