//https://cloud.google.com/text-to-speech/docs/ssml?hl=en

const getPolicy = (cats) => {
  console.log('cats', cats);
  //AGDHJL
  //const types = cats.map(c => c.policyType)
  const uniqueTypes = [...new Set(cats?.map(c => c.policyType && c.policyType))];
  if (uniqueTypes.length === 1) {
    return uniqueTypes[0];
  } else if (uniqueTypes.length === 2) {
    return `${uniqueTypes.join(' and ')}`;
  } else if (uniqueTypes.length > 2) {
    //const lastPart = uniqueTypes.pop();
    //return `${uniqueTypes.join(', ')}, and ${lastPart}.`;
    console.log("uniqueTypes error: too many", uniqueTypes)
    return 'General Liability';
  }
}

const getAC = (auditCode) => {
  return auditCode.split('').join(' . ');
}

//    You can also reach out to your auditor Dan, via email at, dan@auditcake.com or, 9, 1, 7, 7, 2, 5, 1, 5, 1, 1.


export const callTemplate = (variables) => `
 
    This is a call about your ${getPolicy(variables.categories)} insurance policy from U-S-L-I 
    for ${variables?.insuredCompany}.
  
    We are required to conduct an insurance premium audit.
    Failure to respond may jeopardize your insurance coverage with U-S-L-I. 

    To complete your audit please visit, www.auditcake.com, and enter the code, ${getAC(variables?.auditCode)}. .

    Press 1 to repeat, 
    2 to get a text with your audit instructions, 
    3 to get a text to another number, 
    4 to leave a message.
`;
